<template>
  <div class="resources__container">
    <HeaderDropdown
      dropdown-id="resources"
      :left-offset="150"
      :opened-dropdown-id="openedDropdownId"
      :trigger-text="$t('modules.navigation.resources')"
      @dropdownOpened="$emit('dropdownOpened', $event)"
    >
      <div class="resources__content-drop">
        <div class="resources__content-drop-col">
          <div class="resources__content-drop-col-title">
            {{ $t('modules.navigation.resources_dropdown.section_titles.learn').toUpperCase() }}
          </div>
          <div class="resources__content-drop-col-body">
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              :href="supportLink"
            >
              {{ $t('modules.navigation.resources_dropdown.links.knowledge_base') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/thinking-of-starting-a-podcast/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.how_to_start') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/what-is-the-best-mic-for-podcasting/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.best_mics') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/best-headphones-for-podcasting/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.best_headphones') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/how-to-upload-a-podcast-to-spotify/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.how_to_start_spotify') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/how-to-submit-your-rss-feed-to-itunes/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.how_to_start_apple') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/how-to-create-an-rss-feed/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.how_to_create_feed') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/record-and-edit-podcast/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.how_to_record') }}
            </Link>
            <Link
              is-unstyled
              target="_blank"
              class="resources__content-drop-col-body-link"
              href="https://rss.com/blog/podcast-analytics-grow-monetize/"
            >
              {{ $t('modules.navigation.resources_dropdown.links.understanding_analytics') }}
            </Link>
          </div>
        </div>
        <div class="resources__content-drop-col">
          <div class="resources__content-drop-col-title">
            {{ $t('modules.navigation.resources_dropdown.section_titles.more').toUpperCase() }}
          </div>
          <div class="resources__content-drop-col-body">
            <Link
              is-unstyled
              class="resources__content-drop-col-body-link"
              :href="$locatedLink('/switch-to-rss/')"
            >
              {{ $t('modules.navigation.resources_dropdown.links.switch_to_rss') }}
            </Link>
            <Link
              is-unstyled
              class="resources__content-drop-col-body-link"
              :href="$locatedLink('/gift-cards/')"
            >
              {{ $t('modules.navigation.resources_dropdown.links.gift_cards') }}
            </Link>
            <Link
              is-unstyled
              class="resources__content-drop-col-body-link"
              :href="podvizUrl"
            >
              {{ $t('modules.navigation.resources_dropdown.links.podviz') }}
            </Link>
            <Link
              is-unstyled
              class="resources__content-drop-col-body-link"
              :href="$locatedLink('/reviews/')"
            >
              {{ $t('modules.navigation.resources_dropdown.links.reviews') }}
            </Link>
            <Link
              is-unstyled
              class="resources__content-drop-col-body-link"
              :href="changelogUrl"
            >
              {{ $t('modules.navigation.resources_dropdown.links.see_whats_new') }} ✨
            </Link>
          </div>
        </div>
      </div>
    </HeaderDropdown>
  </div>
</template>

<script>
import { config } from '@/config'
import Link from '@/components/common/Link/Link'
import HeaderDropdown from '@/components/modules/header/HeaderDropdown'

export default {
  components: {
    Link,
    HeaderDropdown
  },

  props: {
    openedDropdownId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      changelogUrl: 'https://changelog.rss.com/'
    }
  },

  computed: {
    supportLink () {
      return this.$locatedLink('/support/home/', config.supportLink)
    },

    podvizUrl () {
      return config.podvizUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.resources {
  &__content {
    &-drop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gap;
      @include display-less(desktop) {
        grid-template-columns: 1fr;
        grid-gap: $gap * 2.5;
      }
      &-col {
        &-title {
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 700;
          padding-bottom: $gap * 0.5;
          border-bottom: 1px solid #422965;
          margin-bottom: $gap * 0.5;
          line-height: 21px;
          @include display-less(desktop) {
            color: #2D2D2D;
            border-bottom: 1px solid #DFDFDF;
          }
        }
        &-body {
          display: flex;
          flex-direction: column;

          &-link {
            display: block;
            color: #FFFFFF;
            font-size: 16px;
            padding: $gap * 0.75;
            text-decoration: none;
            font-weight: 400;
            @include display-less(desktop) {
              color: #2D2D2D;
            }

            &:hover {
              @include display(desktop) {
                background-color: #1C0C32;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
