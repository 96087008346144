/*
 ** Core API endpoints:
 ** - POST /auth/refresh-token
*/

import { config } from '~/config'
import { parseIdToken } from '~/lib/utils'

export default {
  async fetch ({ state, rootState, commit, dispatch }, payload) {
    const requestForPublicApi = payload.public
    const requestForAwsStatus = payload.awsStatus

    const COOKIE_IT = this.$cookiz.get('RSS_IT')
    const COOKIE_RT = this.$cookiz.get('RSS_RT')

    const isLocalDevEnv = process.env.IS_LOCAL_DEV_ENV

    let apiUrl = config.apiUrl

    // SSR uses internal communication via K8s service
    if (typeof window === 'undefined' && !isLocalDevEnv) {
      apiUrl = config.coreApiInternalUrl
    }

    const analytics = payload.analytics
    const token = payload.token
    const requestData = payload.data || {}
    const timeout = payload.timeout || null
    const endpoint = payload.endpoint
    const customHeaders = payload.headers || {}
    const method = payload.method || 'GET'
    const includeResponseHeaders = payload.includeResponseHeaders

    if (analytics) {
      apiUrl = config.analyticsUrl
    }

    if (requestForPublicApi) {
      if (typeof window === 'undefined' && !isLocalDevEnv) {
        apiUrl = config.publicApiUrlInternal
      } else {
        apiUrl = config.publicApiUrl
      }
    }

    if (requestForAwsStatus) {
      apiUrl = config.awsStatusUrl
    }

    let headers = {}

    if (token) {
      headers.Authorization = 'Bearer ' + token
    }

    headers = { ...headers, ...customHeaders }

    const axiosRequestConfig = {
      method,
      url: apiUrl + endpoint,
      headers,
      data: requestData,
      timeout
    }

    if (requestForPublicApi) {
      delete axiosRequestConfig.headers
    }

    let result = { success: false }
    let responseHeaders = null

    try {
      let response = await this.$axios(axiosRequestConfig)
      result = response.data
      responseHeaders = response.headers

      if (result.success === false) {
        response = await this.$axios(axiosRequestConfig)
        result = response.data
        responseHeaders = response.headers

        console.log('Retried request response:', result)
      }
    } catch (error) {
      if (requestForPublicApi) {
        throw error
      }

      if (!COOKIE_IT || !COOKIE_RT) {
        throw error
      }

      // other requests with auth
      if (error.response) {
        if (error.response.status === 401) {
          const result = await dispatch('tryAgainAfterTokenRefresh', axiosRequestConfig)
          return result
        } else {
          console.log(error.response)
          result = error.response.data
        }
      }
    }

    if (includeResponseHeaders) {
      return { data: result, headers: responseHeaders }
    }
    return result
  },

  async tryAgainAfterTokenRefresh ({ state, rootState, commit, dispatch }, axiosRequestConfig) {
    let result = { success: false }
    const refreshTokenResponse = await dispatch('refreshToken')
    if (!refreshTokenResponse || !refreshTokenResponse.success) {
      return result
    }
    result = await dispatch('tryForFailedRequestAgain', axiosRequestConfig)
    return result
  },

  async refreshToken ({ state, rootState, commit, dispatch }) {
    const result = { success: false }
    // const IT = this.$cookiz.get('RSS_IT')
    const IT = rootState.auth.idToken
    const RT = this.$cookiz.get('RSS_RT')

    if (!IT || !RT) {
      return result
    }

    const parsedIdToken = parseIdToken(IT)
    const data = {
      username: parsedIdToken.sub,
      'refresh-token': RT
    }

    let response
    try {
      let apiUrl = config.apiUrl
      const isLocalDevEnv = process.env.IS_LOCAL_DEV_ENV

      // SSR uses internal communication via K8s service
      if (typeof window === 'undefined' && !isLocalDevEnv) {
        apiUrl = config.coreApiInternalUrl
      }

      response = await this.$axios({
        method: 'POST',
        url: apiUrl + '/v2/auth/refresh-token',
        data,
        timeout: 10000
      })
    } catch (error) {
      dispatch('removeTokensFromCookie')
    }

    if (!response) {
      console.error('Error obtaining response.')
      dispatch('removeTokensFromCookie')
    }

    const date = new Date()
    const expiredIn = response.data.ExpiresIn * 1000 + date.getTime()
    const idToken = response.data.IdToken
    const accessToken = response.data.AccessToken

    await dispatch('auth/signInInitTokens', { idToken, accessToken, expiredIn }, { root: true })

    result.success = true
    return result
  },

  async tryForFailedRequestAgain ({ state, rootState, commit, dispatch }, axiosRequestConfig) {
    let result = { success: false }

    // const newIdToken = this.$cookiz.get('RSS_IT')
    const newIdToken = rootState.auth.idToken
    const newAccessToken = this.$cookiz.get('RSS_AT')

    if (axiosRequestConfig.headers && axiosRequestConfig.headers.Authorization) {
      axiosRequestConfig.headers.Authorization = 'Bearer ' + newIdToken
    }

    if (axiosRequestConfig.data && axiosRequestConfig.data.accessToken) {
      axiosRequestConfig.data.accessToken = newAccessToken
    }

    try {
      const response = await this.$axios(axiosRequestConfig)
      result = response.data
    } catch (error) {
      // this.$sentry.withScope(() => {
      //   const message = 'Error with call to the API - repeated error'
      //   this.$sentry.captureException(new Error(message))
      // })
      console.log('unknown error on tryForFailedRequestAgain request')
      console.log(error)
    }

    return result
  },

  removeTokensFromCookie () {
    console.log('remove cookies after second try for API access')
    this.$cookiz.remove('RSS_IT')
    this.$cookiz.remove('RSS_AT')
    this.$cookiz.remove('RSS_RT')
    this.$cookiz.remove('RSS_EXPIRED_IN')
  }
}
