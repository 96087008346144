<template lang="pug">
.controller(:class="isFullScreen ? 'controller-full-screen': 'controller-default'")
  .controller__nav(:class="isFullScreen ? 'controller__nav-full-screen': 'controller__nav-default'")
    .controller__nav-backward(
      :class="isFullScreen ? 'controller__nav-backward-full-screen': 'controller__nav-backward-default'"
      href="#"
      @click.prevent="addCurrentTimeSeconds(backwardSeconds)"
    )
      Backward15Icon
    .controller__nav-prev(
      href="#"
      @click.prevent="setPreviousEpisode"
      :class="{'controller__nav-disabled': isFirstEpisodeInsideThePlaylist, 'controller__nav-prev-full-screen': isFullScreen, 'controller__nav-prev-default': !isFullScreen}"
    )
      PrevIcon
    .controller__nav-play(
      :class="isFullScreen ? 'controller__nav-play-full-screen': 'controller__nav-play-default'"
      @click.prevent="$emit('playPauseClick')"
    )
      LoadingIcon(v-if="loading")
      PlayPauseIcon(
        v-else
        :isPlaying="status === 'playing'"
      )
    .controller__nav-next(
      href="#"
      @click.prevent="setNextEpisode"
      :class="{'controller__nav-disabled': isLatestEpisodeInsideThePlaylist, 'controller__nav-next-full-screen': isFullScreen, 'controller__nav-next-default': !isFullScreen}"
    )
      NextIcon
    .controller__nav-forward(
      :class="isFullScreen ? 'controller__nav-forward-full-screen': 'controller__nav-forward-default'"
      href="#"
      @click.prevent="addCurrentTimeSeconds(forwardSeconds)"
    )
      Forward30Icon
  .controller__bar(
    ref="timelineControllerBar"
    :class="isFullScreen ? 'controller__bar-full-screen': 'controller__bar-default'"
  )
    span.controller__bar-time(
      :class="isFullScreen ? 'controller__bar-time-full-screen': 'controller__bar-time-default'"
    ) {{ prettyAudioCurrentTime }}
    .controller__bar-timeline
      input.controller__bar-timeline-input(
        type="range"
        ref="timeline"
        v-model="barTimeValue"
        @mousedown="savePlayerStatus"
        @mouseup="restorePlayerStatus"
        @mouseleave="bigTimelineProgressHead = false"
        @mouseenter="bigTimelineProgressHead = true"
        @change="onBarTimeChange"
        @click="(ev) => timelineClickHandler(ev)"
        @touchstart="(ev) => {bigTimelineProgressHead = true; savePlayerStatus('stopped'); timelineTouchHandler(ev)}"
        @touchend="bigTimelineProgressHead = false; restorePlayerStatus()"
      )
      .controller__bar-timeline-background
        .controller__bar-timeline-progress(
          :style="`width: ${barTimeValue}%;`"
        )
          .controller__bar-timeline-progress-head(
            :style="{'transform': bigTimelineProgressHead ? 'scale(2)': ''}"
          )
    span.controller__bar-duration(
      :class="isFullScreen ? 'controller__bar-duration-full-screen': 'controller__bar-duration-default'"
    ) {{ prettyAudioDurationTime }}
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { getMobileOperatingSystem, prettifyAudioDuration } from '@/lib/utils'

import PrevIcon from '@/components/icons/player/Prev'
import NextIcon from '@/components/icons/player/Next'
import PlayPauseIcon from '@/components/icons/player/PlayPause'
import Backward15Icon from '@/components/icons/player/Backward15'
import Forward30Icon from '@/components/icons/player/Forward30'
import LoadingIcon from '@/components/icons/player/Loading'

export default {
  components: {
    PrevIcon,
    NextIcon,
    PlayPauseIcon,
    Backward15Icon,
    Forward30Icon,
    LoadingIcon
  },
  props: {
    readyState: {
      type: Number,
      required: true
    },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      forwardSeconds: 30,
      backwardSeconds: -15,
      timelineDragging: false,
      bigTimelineProgressHead: false,
      barTimeValue: 0,
      savedPlayerStatus: null
    }
  },

  computed: {
    ...mapState('player', [
      'status',
      'currentTime',
      'currentEpisode',
      'currentPodcast',
      'loading'
    ]),

    ...mapGetters('player', [
      'isFirstEpisodeInsideThePlaylist',
      'isLatestEpisodeInsideThePlaylist',
      'getNextEpisodeInsideThePlaylist',
      'getPreviousEpisodeInsideThePlaylist'
    ]),

    mobileOperatingSystem () {
      return getMobileOperatingSystem()
    },

    episodeDuration () {
      if (this.currentEpisode.itunes_duration) {
        return this.currentEpisode.itunes_duration
      }

      return 0
    },

    prettyAudioCurrentTime () {
      return prettifyAudioDuration(this.barTimeValue / 100 * this.episodeDuration)
    },

    prettyAudioDurationTime () {
      const duration = this.episodeDuration
      const result = prettifyAudioDuration(duration)
      return result
    }
  },

  watch: {
    currentTime (value) {
      this.barTimeValue = value / this.episodeDuration * 100
    }
  },

  methods: {
    ...mapActions('player', [
      'setCurrentPlayingPodcast',
      'setCurrentPlayingEpisode',
      'setSkipTo',
      'setPlayList',
      'setLoadingStatus',
      'setPlayerStatus'
    ]),
    ...mapActions('podcasts', ['get', 'getEpisodeById']),

    onBarTimeChange () {
      const progress = this.barTimeValue
      this.setSkipTo(progress / 100 * this.episodeDuration)
    },

    timelineClickHandler (event) {
      this.timelineSetTimeByOffset(event.offsetX)
    },

    timelineTouchHandler (event) {
      const timeline = this.$refs.timeline

      if (timeline) {
        const timelineX = timeline.getBoundingClientRect().x
        const touchX = event.targetTouches[0].clientX
        const offsetX = Math.max(touchX - timelineX, 0)

        this.timelineSetTimeByOffset(offsetX)
      }
    },

    timelineSetTimeByOffset (offsetX) {
      const timeline = this.$refs.timeline
      const timelineWidth = window.getComputedStyle(timeline).width
      const timeToSeek = (offsetX / parseFloat(timelineWidth)) * this.episodeDuration
      this.setSkipTo(timeToSeek)
    },

    addCurrentTimeSeconds (value) {
      const newValue = Math.min(Math.max(this.currentTime + value, 0), this.episodeDuration - 1)
      this.setSkipTo(newValue)
    },

    async setPreviousEpisode () {
      if (this.isFirstEpisodeInsideThePlaylist) {
        return
      }

      const prevEpisodeId = this.getPreviousEpisodeInsideThePlaylist()
      const prevEpisode = await this.getEpisodeById({
        eid: prevEpisodeId,
        slug: this.currentPodcast.slug
      })
      this.setCurrentPlayingEpisode(prevEpisode)
      this.setSkipTo(0)
    },

    async setNextEpisode () {
      if (this.isLatestEpisodeInsideThePlaylist) {
        return
      }
      const nextEpisodeId = this.getNextEpisodeInsideThePlaylist()
      if (nextEpisodeId === 'random') {
        return
      }

      const nextEpisode = await this.getEpisodeById({
        eid: nextEpisodeId,
        slug: this.currentPodcast.slug
      })
      this.setCurrentPlayingEpisode(nextEpisode)
      this.setSkipTo(0)
    },

    savePlayerStatus () {
      this.savedPlayerStatus = this.status
      this.setPlayerStatus('stopped')
    },

    restorePlayerStatus () {
      if (this.savedPlayerStatus) {
        this.setPlayerStatus(this.savedPlayerStatus)
        this.savedPlayerStatus = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.controller {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: $gap * 0.5;

  @include display-less(phablet) {
    flex-direction: column-reverse;
  }

  &-full-screen {
    flex-direction: column-reverse;
    align-items: start;
    height: fit-content;
    @include display-less(phablet) {
      gap: $gap * 2.5;
    }
  }

  &-default {
    flex-direction: column;
    align-items: center;
    height: 100%;
    @include display-less(phablet) {
      flex-direction: unset;
      min-width: 37px;
      max-width: 37px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @include display-less(phablet) {
      width: 100%;
      justify-content: space-between;
    }

    &-full-screen {
      gap: $gap * 1.75;
    }
    &-default {
      gap: $gap * 2;
    }

    &-prev,
    &-next {
      outline: none;
      cursor: pointer;
      &-full-screen {
        width: 16px;
        height: 16px;
        @include display-less(phablet) {
          width: 22px;
          height: 22px;
        }
      }
      &-default {
        width: 22px;
        height: 22px;
        @include display-less(phablet) {
          display: none;
        }
      }
    }
    &-backward,
    &-forward {
      outline: none;
      cursor: pointer;

      &-full-screen {
        width: 22px;
        height: 22px;
        @include display-less(phablet) {
          width: 29px;
          height: 29px;
        }
      }
      &-default {
        width: 29px;
        height: 29px;
        @include display-less(phablet) {
          display: none;
        }
      }
    }
    &-play {
      outline: none;
      cursor: pointer;

      &-full-screen {
        width: 29px;
        height: 29px;
        @include display-less(phablet) {
          width: 59px;
          height: 59px;
        }
      }
      &-default {
        width: 44px;
        height: 44px;
        @include display-less(phablet) {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
        }
      }
    }
    &-disabled {
      opacity: 0.5;
    }
  }

  &__bar {
    width: 100%;
    display: flex;
    margin-bottom: 4px;
    align-items: center;
    position: relative;

    &-default {
      @include display-less(phablet) {
        display: none;
      }
    }

    &-time {
      color: #ffffff;
      line-height: 1;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.3px;
      &-full-screen {
        @include display-less(phablet) {
          position: absolute;
          top: 20px;
        }
      }
    }

    &-duration {
      color: #ffffff;
      line-height: 1;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.3px;
      &-full-screen {
        @include display-less(phablet) {
          position: absolute;
          top: 20px;
          right: 0;
        }
      }
    }

    &-timeline {
      width: 100%;
      height: 16px;
      margin: 0 8px;
      position: relative;
      display: flex;
      align-items: center;

      &-input {
        width: 100%;
        -webkit-appearance: none;
        height: 16px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 40;
        cursor: pointer;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 8px;
          height: 8px;
        }

        &:focus {
          outline: none;
        }
      }
      &-background {
        width: 100%;
        background-color: #1c0c32;
        border-radius: 2px;
      }

      &-progress {
        width: 0px;
        height: 4px;
        background-color: #fff;
        position: relative;
        top: 0;
        border-radius: 2px;

        &-head {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          right: -2px;
          top: -2.5px;
          transition: all 0.2s ease-in-out;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
