<template>
  <component :is="icon" :class="classes" />
</template>

<script>
import Close from './icons/Close'
import CloudCheck from './icons/CloudCheck'
import Upload from './icons/Upload'
import NavigatorArrow from './icons/NavigatorArrow'
import CaretLeft from './icons/CaretLeft'
import ArrowRight from './icons/ArrowRight'
import Bell from './icons/Bell'
import Distribution from './icons/Distribution'
import YouTube from './icons/YouTube'
import Accessibility from './icons/Accessibility'
import Connection from './icons/Connection'
import Language from './icons/Language'
import AddUser from './icons/AddUser'
import EditUser from './icons/EditUser'
import GroupUser from './icons/GroupUser'
import Edit from './icons/Edit'
import Settings from './icons/Settings'
import EyeSlash from './icons/EyeSlash'
import MessageDisabled from './icons/MessageDisabled'
import ShieldCheck from './icons/ShieldCheck'
import Paste from './icons/Paste'
import ColorPicker from './icons/ColorPicker'
import Phone from './icons/Phone'
import Mouse from './icons/Mouse'
import Share from './icons/Share'
import Speaker from './icons/Speaker'
import LightningBolt from './icons/LightningBolt'
import CodeWindow from './icons/CodeWindow'
import Bookmark from './icons/Bookmark'
import Grid from './icons/Grid'
import Globe from './icons/Globe'
import Clock from './icons/Clock'
import Waveform from './icons/Waveform'
import MediaFile from './icons/MediaFile'
import Chest from './icons/Chest'

export default {
  components: {
    Close,
    CloudCheck,
    Upload,
    NavigatorArrow,
    CaretLeft,
    ArrowRight,
    Bell,
    Distribution,
    YouTube,
    Accessibility,
    Connection,
    Language,
    AddUser,
    EditUser,
    GroupUser,
    Edit,
    Settings,
    EyeSlash,
    MessageDisabled,
    ShieldCheck,
    Paste,
    Phone,
    ColorPicker,
    Mouse,
    Share,
    Speaker,
    LightningBolt,
    CodeWindow,
    Bookmark,
    Grid,
    Globe,
    Clock,
    Waveform,
    MediaFile,
    Chest
  },

  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'white'
    },
    size: {
      type: String,
      default: 'medium'
    }
  },

  computed: {
    icon () {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1)
    },

    classes () {
      return [
        'commonIcon',
        `commonIcon_${this.size ? this.size : 'medium'}`,
        `commonIcon_${this.color ? this.color : 'white'}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonIcon {
  vertical-align: middle;

  /deep/ path {
    fill: currentcolor;
  }

  &_medium {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &_white {
    color: #fff;
  }

  &_grey {
    color: #797088;
  }

  &_lightPurple {
    color: $colorLightPurple;
  }
}
</style>
