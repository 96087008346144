import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5bfd5530 = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _6bfe6a32 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _73e24fc1 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _71c1faea = () => interopDefault(import('../pages/features/index.vue' /* webpackChunkName: "pages/features/index" */))
const _3393b91a = () => interopDefault(import('../pages/gift-cards/index.vue' /* webpackChunkName: "pages/gift-cards/index" */))
const _c4c89e74 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _4e16ad99 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _27e27149 = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _3162a068 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _46ec5cbe = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _01300802 = () => interopDefault(import('../pages/switch-to-rss/index.vue' /* webpackChunkName: "pages/switch-to-rss/index" */))
const _974947a2 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _22ec7522 = () => interopDefault(import('../pages/community/categories/index.vue' /* webpackChunkName: "pages/community/categories/index" */))
const _13d405b6 = () => interopDefault(import('../pages/community/explore/index.vue' /* webpackChunkName: "pages/community/explore/index" */))
const _3481de50 = () => interopDefault(import('../pages/features/audio-to-video.vue' /* webpackChunkName: "pages/features/audio-to-video" */))
const _2228bdc5 = () => interopDefault(import('../pages/features/collaborators.vue' /* webpackChunkName: "pages/features/collaborators" */))
const _77c80fc1 = () => interopDefault(import('../pages/features/embeddable-player.vue' /* webpackChunkName: "pages/features/embeddable-player" */))
const _32dbe77a = () => interopDefault(import('../pages/features/episode-transcripts.vue' /* webpackChunkName: "pages/features/episode-transcripts" */))
const _70189095 = () => interopDefault(import('../pages/features/podcast-analytics.vue' /* webpackChunkName: "pages/features/podcast-analytics" */))
const _b93cbb76 = () => interopDefault(import('../pages/features/podcast-distribution.vue' /* webpackChunkName: "pages/features/podcast-distribution" */))
const _d10c63ee = () => interopDefault(import('../pages/features/podcast-hosting.vue' /* webpackChunkName: "pages/features/podcast-hosting" */))
const _aadc56ec = () => interopDefault(import('../pages/features/podcast-monetization.vue' /* webpackChunkName: "pages/features/podcast-monetization" */))
const _e093962c = () => interopDefault(import('../pages/features/podcast-website.vue' /* webpackChunkName: "pages/features/podcast-website" */))
const _58b3f2a8 = () => interopDefault(import('../pages/features/podcasting2.vue' /* webpackChunkName: "pages/features/podcasting2" */))
const _51ab8aa0 = () => interopDefault(import('../pages/features/privacy.vue' /* webpackChunkName: "pages/features/privacy" */))
const _0d486e2b = () => interopDefault(import('../pages/features/social-media-sharing.vue' /* webpackChunkName: "pages/features/social-media-sharing" */))
const _f75d5234 = () => interopDefault(import('../pages/gift-cards/details/index.vue' /* webpackChunkName: "pages/gift-cards/details/index" */))
const _b534ad0a = () => interopDefault(import('../pages/gift-cards/thanks/index.vue' /* webpackChunkName: "pages/gift-cards/thanks/index" */))
const _75b786c4 = () => interopDefault(import('../pages/switch-to-rss/requested.vue' /* webpackChunkName: "pages/switch-to-rss/requested" */))
const _878eb406 = () => interopDefault(import('../pages/community/categories/_category/index.vue' /* webpackChunkName: "pages/community/categories/_category/index" */))
const _367d1127 = () => interopDefault(import('../pages/community/categories/_category/_subcategory/index.vue' /* webpackChunkName: "pages/community/categories/_category/_subcategory/index" */))
const _48dcf457 = () => interopDefault(import('../pages/podcasts/_slug/index.vue' /* webpackChunkName: "pages/podcasts/_slug/index" */))
const _010cccec = () => interopDefault(import('../pages/podcasts/_slug/_episode/index.vue' /* webpackChunkName: "pages/podcasts/_slug/_episode/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliates/",
    component: _5bfd5530,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___en"
  }, {
    path: "/community/",
    component: _6bfe6a32,
    pathToRegexpOptions: {"strict":true},
    name: "community___en"
  }, {
    path: "/cookie-policy/",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___en"
  }, {
    path: "/es/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/features/",
    component: _71c1faea,
    pathToRegexpOptions: {"strict":true},
    name: "features___en"
  }, {
    path: "/gift-cards/",
    component: _3393b91a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___en"
  }, {
    path: "/health/",
    component: _c4c89e74,
    pathToRegexpOptions: {"strict":true},
    name: "health___en"
  }, {
    path: "/it/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___en"
  }, {
    path: "/press/",
    component: _27e27149,
    pathToRegexpOptions: {"strict":true},
    name: "press___en"
  }, {
    path: "/pricing/",
    component: _3162a068,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en"
  }, {
    path: "/privacy-policy/",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/reviews/",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___en"
  }, {
    path: "/switch-to-rss/",
    component: _01300802,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss___en"
  }, {
    path: "/terms-of-service/",
    component: _974947a2,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-service___en"
  }, {
    path: "/community/categories/",
    component: _22ec7522,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories___en"
  }, {
    path: "/community/explore/",
    component: _13d405b6,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___en"
  }, {
    path: "/es/affiliates/",
    component: _5bfd5530,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___es"
  }, {
    path: "/es/community/",
    component: _6bfe6a32,
    pathToRegexpOptions: {"strict":true},
    name: "community___es"
  }, {
    path: "/es/cookie-policy/",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___es"
  }, {
    path: "/es/features/",
    component: _71c1faea,
    pathToRegexpOptions: {"strict":true},
    name: "features___es"
  }, {
    path: "/es/gift-cards/",
    component: _3393b91a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___es"
  }, {
    path: "/es/health/",
    component: _c4c89e74,
    pathToRegexpOptions: {"strict":true},
    name: "health___es"
  }, {
    path: "/es/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___es"
  }, {
    path: "/es/press/",
    component: _27e27149,
    pathToRegexpOptions: {"strict":true},
    name: "press___es"
  }, {
    path: "/es/pricing/",
    component: _3162a068,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___es"
  }, {
    path: "/es/privacy-policy/",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___es"
  }, {
    path: "/es/reviews/",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___es"
  }, {
    path: "/es/switch-to-rss/",
    component: _01300802,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss___es"
  }, {
    path: "/es/terms-of-service/",
    component: _974947a2,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-service___es"
  }, {
    path: "/features/audio-to-video/",
    component: _3481de50,
    pathToRegexpOptions: {"strict":true},
    name: "features-audio-to-video___en"
  }, {
    path: "/features/collaborators/",
    component: _2228bdc5,
    pathToRegexpOptions: {"strict":true},
    name: "features-collaborators___en"
  }, {
    path: "/features/embeddable-player/",
    component: _77c80fc1,
    pathToRegexpOptions: {"strict":true},
    name: "features-embeddable-player___en"
  }, {
    path: "/features/episode-transcripts/",
    component: _32dbe77a,
    pathToRegexpOptions: {"strict":true},
    name: "features-episode-transcripts___en"
  }, {
    path: "/features/podcast-analytics/",
    component: _70189095,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-analytics___en"
  }, {
    path: "/features/podcast-distribution/",
    component: _b93cbb76,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-distribution___en"
  }, {
    path: "/features/podcast-hosting/",
    component: _d10c63ee,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-hosting___en"
  }, {
    path: "/features/podcast-monetization/",
    component: _aadc56ec,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-monetization___en"
  }, {
    path: "/features/podcast-website/",
    component: _e093962c,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-website___en"
  }, {
    path: "/features/podcasting2/",
    component: _58b3f2a8,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcasting2___en"
  }, {
    path: "/features/privacy/",
    component: _51ab8aa0,
    pathToRegexpOptions: {"strict":true},
    name: "features-privacy___en"
  }, {
    path: "/features/social-media-sharing/",
    component: _0d486e2b,
    pathToRegexpOptions: {"strict":true},
    name: "features-social-media-sharing___en"
  }, {
    path: "/gift-cards/details/",
    component: _f75d5234,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-details___en"
  }, {
    path: "/gift-cards/thanks/",
    component: _b534ad0a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-thanks___en"
  }, {
    path: "/it/affiliates/",
    component: _5bfd5530,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates___it"
  }, {
    path: "/it/community/",
    component: _6bfe6a32,
    pathToRegexpOptions: {"strict":true},
    name: "community___it"
  }, {
    path: "/it/cookie-policy/",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___it"
  }, {
    path: "/it/features/",
    component: _71c1faea,
    pathToRegexpOptions: {"strict":true},
    name: "features___it"
  }, {
    path: "/it/gift-cards/",
    component: _3393b91a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards___it"
  }, {
    path: "/it/health/",
    component: _c4c89e74,
    pathToRegexpOptions: {"strict":true},
    name: "health___it"
  }, {
    path: "/it/maintenance/",
    component: _4e16ad99,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance___it"
  }, {
    path: "/it/press/",
    component: _27e27149,
    pathToRegexpOptions: {"strict":true},
    name: "press___it"
  }, {
    path: "/it/pricing/",
    component: _3162a068,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___it"
  }, {
    path: "/it/privacy-policy/",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___it"
  }, {
    path: "/it/reviews/",
    component: _46ec5cbe,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___it"
  }, {
    path: "/it/switch-to-rss/",
    component: _01300802,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss___it"
  }, {
    path: "/it/terms-of-service/",
    component: _974947a2,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-service___it"
  }, {
    path: "/switch-to-rss/requested/",
    component: _75b786c4,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss-requested___en"
  }, {
    path: "/es/community/categories/",
    component: _22ec7522,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories___es"
  }, {
    path: "/es/community/explore/",
    component: _13d405b6,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___es"
  }, {
    path: "/es/features/audio-to-video/",
    component: _3481de50,
    pathToRegexpOptions: {"strict":true},
    name: "features-audio-to-video___es"
  }, {
    path: "/es/features/collaborators/",
    component: _2228bdc5,
    pathToRegexpOptions: {"strict":true},
    name: "features-collaborators___es"
  }, {
    path: "/es/features/embeddable-player/",
    component: _77c80fc1,
    pathToRegexpOptions: {"strict":true},
    name: "features-embeddable-player___es"
  }, {
    path: "/es/features/episode-transcripts/",
    component: _32dbe77a,
    pathToRegexpOptions: {"strict":true},
    name: "features-episode-transcripts___es"
  }, {
    path: "/es/features/podcast-analytics/",
    component: _70189095,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-analytics___es"
  }, {
    path: "/es/features/podcast-distribution/",
    component: _b93cbb76,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-distribution___es"
  }, {
    path: "/es/features/podcast-hosting/",
    component: _d10c63ee,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-hosting___es"
  }, {
    path: "/es/features/podcast-monetization/",
    component: _aadc56ec,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-monetization___es"
  }, {
    path: "/es/features/podcast-website/",
    component: _e093962c,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-website___es"
  }, {
    path: "/es/features/podcasting2/",
    component: _58b3f2a8,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcasting2___es"
  }, {
    path: "/es/features/privacy/",
    component: _51ab8aa0,
    pathToRegexpOptions: {"strict":true},
    name: "features-privacy___es"
  }, {
    path: "/es/features/social-media-sharing/",
    component: _0d486e2b,
    pathToRegexpOptions: {"strict":true},
    name: "features-social-media-sharing___es"
  }, {
    path: "/es/gift-cards/details/",
    component: _f75d5234,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-details___es"
  }, {
    path: "/es/gift-cards/thanks/",
    component: _b534ad0a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-thanks___es"
  }, {
    path: "/es/switch-to-rss/requested/",
    component: _75b786c4,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss-requested___es"
  }, {
    path: "/it/community/categories/",
    component: _22ec7522,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories___it"
  }, {
    path: "/it/community/explore/",
    component: _13d405b6,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___it"
  }, {
    path: "/it/features/audio-to-video/",
    component: _3481de50,
    pathToRegexpOptions: {"strict":true},
    name: "features-audio-to-video___it"
  }, {
    path: "/it/features/collaborators/",
    component: _2228bdc5,
    pathToRegexpOptions: {"strict":true},
    name: "features-collaborators___it"
  }, {
    path: "/it/features/embeddable-player/",
    component: _77c80fc1,
    pathToRegexpOptions: {"strict":true},
    name: "features-embeddable-player___it"
  }, {
    path: "/it/features/episode-transcripts/",
    component: _32dbe77a,
    pathToRegexpOptions: {"strict":true},
    name: "features-episode-transcripts___it"
  }, {
    path: "/it/features/podcast-analytics/",
    component: _70189095,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-analytics___it"
  }, {
    path: "/it/features/podcast-distribution/",
    component: _b93cbb76,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-distribution___it"
  }, {
    path: "/it/features/podcast-hosting/",
    component: _d10c63ee,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-hosting___it"
  }, {
    path: "/it/features/podcast-monetization/",
    component: _aadc56ec,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-monetization___it"
  }, {
    path: "/it/features/podcast-website/",
    component: _e093962c,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcast-website___it"
  }, {
    path: "/it/features/podcasting2/",
    component: _58b3f2a8,
    pathToRegexpOptions: {"strict":true},
    name: "features-podcasting2___it"
  }, {
    path: "/it/features/privacy/",
    component: _51ab8aa0,
    pathToRegexpOptions: {"strict":true},
    name: "features-privacy___it"
  }, {
    path: "/it/features/social-media-sharing/",
    component: _0d486e2b,
    pathToRegexpOptions: {"strict":true},
    name: "features-social-media-sharing___it"
  }, {
    path: "/it/gift-cards/details/",
    component: _f75d5234,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-details___it"
  }, {
    path: "/it/gift-cards/thanks/",
    component: _b534ad0a,
    pathToRegexpOptions: {"strict":true},
    name: "gift-cards-thanks___it"
  }, {
    path: "/it/switch-to-rss/requested/",
    component: _75b786c4,
    pathToRegexpOptions: {"strict":true},
    name: "switch-to-rss-requested___it"
  }, {
    path: "/es/community/categories/:category/",
    component: _878eb406,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category___es"
  }, {
    path: "/it/community/categories/:category/",
    component: _878eb406,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category___it"
  }, {
    path: "/es/community/categories/:category/:subcategory/",
    component: _367d1127,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category-subcategory___es"
  }, {
    path: "/it/community/categories/:category/:subcategory/",
    component: _367d1127,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category-subcategory___it"
  }, {
    path: "/community/categories/:category/",
    component: _878eb406,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category___en"
  }, {
    path: "/es/podcasts/:slug?/",
    component: _48dcf457,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug___es"
  }, {
    path: "/it/podcasts/:slug?/",
    component: _48dcf457,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug___it"
  }, {
    path: "/community/categories/:category/:subcategory/",
    component: _367d1127,
    pathToRegexpOptions: {"strict":true},
    name: "community-categories-category-subcategory___en"
  }, {
    path: "/es/podcasts/:slug?/:episode/",
    component: _010cccec,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug-episode___es"
  }, {
    path: "/it/podcasts/:slug?/:episode/",
    component: _010cccec,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug-episode___it"
  }, {
    path: "/podcasts/:slug?/",
    component: _48dcf457,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug___en"
  }, {
    path: "/podcasts/:slug?/:episode/",
    component: _010cccec,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts-slug-episode___en"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
